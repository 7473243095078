@import 'styles/theme/theme';

.root {
  width: 100%;
  height: 100%;
  position: relative;
}

.player {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba($black, 0.6);
  background: linear-gradient(
    180deg,
    rgba($black, 1) 0%,
    rgba($black, 0.6) 20%,
    rgba($black, 0.6) 80%,
    rgba($black, 1) 100%
  );

  picture {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  &.isPlaying {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mediaIcon {
  font-size: 30px;
  cursor: pointer;
  color: $white;
}

.playIcon {
  cursor: pointer;
  color: $white;
  font-size: 32px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.videoplayer {
  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.6);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &_content {
      position: relative;
      width: 100%;
      height: 100%;
      background: $black;
    }

    &_header {
      width: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
    }

    &_close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      color: $white;
      font-size: 30px;
      z-index: 3;
    }

    &_overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba($black, 0.6);
      padding: 16px;
      pointer-events: none;
      color: $white;
    }
  }
}

.noscriptImage {
  width: 100%;
  height: 100%;
}
