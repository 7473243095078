@import 'styles/theme/theme';

@import 'styles/theme/theme';

.root {
  height: 100%;
  width: 100%;
  position: relative;

  &:hover {
    .icon {
      opacity: 1;
      &__disabled {
        opacity: 0.1;
      }
    }
  }
}

.content {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar { 
    width: 0;
    height: 0;
  }
}

.wrap {
  flex-wrap: wrap;
}

.slide {
  height: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  &_active {
    outline: 2px solid $white;
  }
}

.icon {
  z-index: 2;
  opacity: 0.8;
  position: absolute;
  padding: 4px;
  height: 26px;
  width: 26px;
  margin: auto;
  top: 0;
  bottom: 0;
  cursor: pointer;
  background: rgba($black, 0.80);
  border-radius: 50%;
  svg {
    margin-top: 2px;
    font-size: 14px;
    color: $white;
  }
  &__blue {
    svg {
      color: $blue;
    }
  }
  &__left {
    left: 8px;
    svg {
      margin-left: 1px;
    }
  }
  &__right {
    right: 8px;
    svg {
      margin-left: 2px;
    }
  }
  &__disabled {
    opacity: 0.1;
  }
}
