@import 'styles/theme/theme';

.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  white-space: normal;
  @include noSelection();
}

.wrapper {
  width: 100%;
  height: 100%;
  padding: 4px;
  background-color: $primaryGrey;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.word {
  font-weight: bold;
  height: 100%;
  text-transform: uppercase;
  line-height: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &.text {
    line-height: 0.8;
  }
}