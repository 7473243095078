@import 'styles/theme/theme';

.mediaCard {
  &__details {
    padding: 8px;
    gap: 6px;
    min-height: 25px;
    background-color: $whiteSmoke;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.image {
  picture {
    width: 100%;
    height: 100%;
  }
}