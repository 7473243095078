@import 'styles/theme/theme';

.mediaInfo {
  .body {
    display: flex;
    align-items: center;
    &__imageWrapper {
      width: 40px;
      height: 40px;
      border: 2px solid $white;
      border-radius: 50%;
      overflow: hidden;
    }
    &__content {
      margin-left: 8px;
      &_text {
        display: flex;
        flex-direction: column;
        .title, .subTitle {
          color: $white;
          font-weight:  $font-weight-medium;
        }
        .title {
          font-size: $font-size-20;
        }
      }
    }
  }

  .description {
    &__text {
      color: $white;
    }
  }
}