.card {
  height: 100%;
  width: 100%;
  &__wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  
    .link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: pointer;
    }
  }
}